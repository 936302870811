<template>
  <popup-modal ref="popup" class="download_modal">
    <h2 class="heading_2" style="margin-top: 0">{{ title }}</h2>
    <p>{{ message }}</p>
    <div class="btns">
      <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
      <span class="ok-btn btn btn_primary" @click="_confirm">{{ okButton }}</span>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmDialogue",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, 
    okButton: undefined,
    cancelButton: "Cancel", // text for cancel button

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style scoped>
.btns {
    display: flex;
    flex-direction: row;
    justify-content:center;
}

.ok-btn {
    cursor: pointer;
    min-width: auto;
    padding: 9px 15px!important;
    text-transform: uppercase!important;
    height: 35px;
    font-size: 12px!important;
}

.cancel-btn {
  padding: 5px 16px 3px;
    background-color: #fff;
    color: var(--primary_color);
    border: 1px solid var(--primary_color);
    border-radius: 25px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: 15px;
    height: 35px;
}
h2.heading_2 {
    font-size: 20px!important;
    margin-bottom: 15px!important;
}
.download_modal p {
    text-align: center;
    margin: 30px 0!important;
}
</style>