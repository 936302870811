<template>
  <div class="main_content program_emp_search align-items-center" style="min-height: 80vh">
    <div class="row justify-content-between mb_40">
      <div class="col-12 col-md-12 col-xl-5 col-xxl-5">
        <div class="heading padd_ipad">
          <h2 class="Employee_head">Employee Search</h2>
          <h3 class="Program_dashboard_head" v-if="$route.name == 'employee_search'"> Program Manager Dashboard </h3>
          <h3 class="Program_dashboard_head" v-if="$route.name == 'evaluator_employee_search'">
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'evaluator' }"
            >
              Program Manager Dashboard
            </router-link>
            / -->
            <router-link class="text-darks hov_router" :to="{ name: 'evaluator' }">
              Evaluator
            </router-link>
            /
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'evaluator' }"
            >
              Employee Search
            </router-link> -->
            <span class="breadcrumb-item active"> Employee Search</span>
          </h3>
          <h3 class="Program_dashboard_head" v-if="$route.name == 'client_employee_search'">
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'client_manager' }"
            >
              PBE Management Dashboard
            </router-link>
            / -->
            <router-link class="text-darks hov_router" :to="{ name: 'client_manager' }">
              Program Management
            </router-link>
            /
            <span class="breadcrumb-item active" style="color: #ff9500 !important;"> {{ clientName }}</span>
            <!-- <router-link
              class="text-darks hov_router"
              :to="{ name: 'client_manager' }"
            >
              Employee Search
            </router-link> -->
          </h3>
        </div>
      </div>
      <div class="col-12 col-md-12 col-xl-7 col-xxl-7">
        <div class="align-items-center d-flex justify-content-end w-100">
          <div class="date_toolbar_main">
            <div class="bg-white date-toolbar region_bar_add">
              <div class="row me-0">
                <div class="col-xs-12 col-md-12 col-lg-9 align-self-center">
                  <div class="">
                    <div class="bg-white date-toolbar" style="box-shadow: none">
                      <div class="row">
                        <div class="col-xs-12 col-md-12 align-self-center">
                          <div class="row">
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="date">
                                <div class="heading_5 Top_date">From</div>
                                <div class="
                                    text_black
                                    font_size_14
                                    d-flex
                                    align-items-center
                                  ">
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                      viewBox="0 0 17.5 17.5">
                                      <path data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)" style=" fill: none;
                                          stroke-linecap: round;
                                          stroke-linejoin: round;
                                          stroke-width: 1.5px;
                                        " />
                                    </svg>
                                  </span>
                                  <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                                    :format="customFormatter" @selected="changeStartDate"
                                    :disabledDates="disabledFutureDates"></datepicker>
                                  <p class="min-date-value d-none">
                                    {{ listConfig.start_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-6">
                              <div class="date todate pl_6">
                                <div class="heading_5 Top_date">To</div>
                                <div class="
                                    text_black
                                    font_size_14
                                    d-flex
                                    align-items-center
                                  ">
                                  <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                      viewBox="0 0 17.5 17.5">
                                      <path data-name="Path 131"
                                        d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                        transform="translate(-2.25 -2.25)" style="
                                          fill: none;
                                          stroke-linecap: round;
                                          stroke-linejoin: round;
                                          stroke-width: 1.5px;
                                        " />
                                    </svg>
                                  </span>
                                  <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date"
                                    :format="customFormatter" @selected="changeEndDate" :disabled="isDisabled"
                                    :disabledDates="disabledDates"></datepicker>
                                  <p class="max-date-value d-none">
                                    {{ listConfig.end_date | formatDate }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-12 col-lg-3 align-self-center">
                  <div class="form-group for_ipad top_by_region">
                    <v-select :reduce="(region_list) => region_list.id" :options="region_list" label="name"
                      placeholder="By Region" name="Region" v-model="listConfig.regionBy" v-validate="'required'"
                      @input="getEmployeesBysearch" browserAutocomplete="false" autocomplete="off" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a href="javascript:" class="reset_btn" @click="resetAll">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
              y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
              <metadata>
                Svg Vector Icons : http://www.onlinewebfonts.com/icon
              </metadata>
              <g>
                <path
                  d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z" />
              </g>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="row" v-if="checkReportType == 3">
      <div class="col-md-12 mb-3">
        <div class="alche_btn tab_report toggle_switch list_card_switch me-0 my-2">
          <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs btn-reports">
            <li role="presentation" class="nav-item list-item">
              <button id="current-tab" data-bs-toggle="tab" data-bs-target="#current" type="button" role="tab"
                aria-controls="current" aria-selected="true" class="nav-link list-link active">
                Current Reports
              </button>
            </li>
            <li role="presentation" class="nav-item list-item">
              <button id="previous-tab" data-bs-toggle="tab" data-bs-target="#previous" type="button" role="tab"
                aria-controls="previous" aria-selected="false" class="nav-link list-link">
                Previous Reports
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div id="myTabContent" class="tab-content">
      <div id="current" role="tabpanel" aria-labelledby="#current"
        :class="checkReportType != 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
        <!-- <div class="table_card"> -->
        <div class="row dataTables_wrapper no-footer" id="datatable_wrapper">
          <div class="
              col-xl-3 col-lg-3 col-md-12 col-xs-12
              text-start text-md-start text-lg-start
              align-self-center
              my-2
              mb-2 mb-lg-5
            ">
            <div class="dataTables_length float_none text-center text-lg-start" id="datatable_length">
              <label>Show
                <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                  @change="getEmployeesBysearch">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>500</option>
                </select>
                entries</label>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 col-xs-12">
            <div class="
                d-flex
                justify-content-lg-end justify-content-center
                align-items-center
                flex-wrap
                mb-4 mb-lg-4
              " id="mobileCenter">
              <div id="datatable_filter" class="dataTables_filter my-2" v-if="!reviewCheckbox.length">

                <label class="mb-0"><input type="search" class="" placeholder="Search" aria-controls="datatable"
                    v-model="listConfig.search_string" v-on:keyup.enter="getEmployeesBysearch" />
                  <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getEmployeesBysearch"
                    class="search_icon icon_level">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="listConfig.search_string" @click="
                    listConfig.search_string = null;
                  getEmployeesBysearch();
                  " class="crossicon">
                    X
                  </a>
                </label>
              </div>
              <div class="visited_pages status_box marg_left wdth_sts my-2">
                <div class="heading">
                  <div class="form-group status_drop mb-0">
                    <v-select @input="getEmployeesBysearch" :reduce="(status_list) => status_list.id"
                      :options="status_list" label="name" placeholder="All Status" name="Status"
                      v-model="listConfig.search_status" v-validate="'required'" v-if="!reviewCheckbox.length" />
                    <small class="error text-danger" v-show="errors.has('addUserForm.Status')">
                      {{ errors.first("addUserForm.Status") }}
                    </small>
                  </div>
                </div>
              </div>
              <a href="javascript:" class="ml_12 xl_download" v-if="reviewCheckbox.length"
                @click="exportTableData('current')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g data-name="Group 12689">
                    <path data-name="Path 114"
                      d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                      transform="translate(-3 -3)" style="
                        fill: none;
                        stroke: #a9a9a9;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </g>
                </svg>
                Download
              </a>
              <div class="
                  tab_report
                  toggle_switch
                  marg_left
                  list_card_switch
                  me-0
                  my-2
                " v-show="!reviewCheckbox.length">
                <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs">
                  <li role="presentation" class="nav-item list-item">
                    <button id="percent1-tab" data-bs-toggle="tab" data-bs-target="#percent1" type="button" role="tab"
                      aria-controls="percent1" aria-selected="true" class="nav-link list-link active">
                      List
                    </button>
                  </li>
                  <li role="presentation" class="nav-item list-item">
                    <button id="hash1-tab" data-bs-toggle="tab" data-bs-target="#hash1" type="button" role="tab"
                      aria-controls="hash1" aria-selected="false" class="nav-link list-link">
                      Card
                    </button>
                  </li>
                </ul>
              </div>
              <div class="table_search ergo_eval_table" v-if="$route.name == 'employee_search' && users_data.length">
                <div class="d-flex align-items-center">
                  <a href="javascript:" class="ml_12 xl_download mt16-0 me-2" @click="exportAllData('current')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <g data-name="Group 12689">
                        <path data-name="Path 114"
                          d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                          transform="translate(-3 -3)" style="
                              fill: none;
                              stroke: #1a191c;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 2px;
                            " />
                      </g>
                    </svg>
                    Download All
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <!-- <div
                    id="datatable_wrapper"
                    class="dataTables_wrapper no-footer">
                  </div> -->
            <div id="myTab1Content" class="tab-content">
              <div id="percent1" role="tabpanel" aria-labelledby="#percent1" class="tab-pane fade active show">
                <div class="table_card all_user">
                  <div class="table-responsive table_audit_log report_table emp_table">
                    <table align="justify" class="table table-bordered table dataTable no-footer">
                      <thead>
                        <tr>
                          <th style="min-width: 10px" class="sort sml_check" v-if="$route.name != 'employee_search'">
                            <div class="form_check_box d-flex align-items-center mt-1">
                              <input type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                                class="form-check-input ms-0" @change="checkSelected($event)" />
                              <label for="flexCheckDefault" class=""> </label>
                            </div>
                          </th>
                          <th align="center" class="sort sml_n_last_first">
                            <!-- <input type="checkbox" value="" aria-label="..." id="flexCheckDefault" v-model="isCheckAll"
                                class="form-check-input ms-0 usercheckbox me-3" @click="userAllcheck" />
                              <label for="flexCheckDefault" class=""> </label> -->
                            <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'first_name'"
                              @sorting="sorting($event)" />
                          </th>
                          <th align="center" class="sort sml_n_last_first">
                            <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'last_name'"
                              @sorting="sorting($event)" />
                          </th>
                          <th align="center" class="sort">
                            <SortIcons :lable="'Email Address'" :listConfig="listConfig" :sortBy="'email'"
                              @sorting="sorting($event)" />
                          </th>
                          <th align="center" class="sort sml_region">
                            <SortIcons :lable="'Region'" :listConfig="listConfig" :sortBy="'region'"
                              @sorting="sorting($event)" />
                          </th>
                          <th v-if="showSelfAssessment" align="center" style="text-align: center">
                            Self-Assessment <br />
                            Activity
                          </th>
                          <th align="center" style="text-align: center" class="sml_region">
                            Ergo Eval <br />
                            Activity
                          </th>
                          <th align="center" style="text-align: center" scope="col" class="sml_region">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Main Loop -->
                        <tr v-for="(user, indx) in users_data" :key="indx">
                          <td style="min-width: 10px" v-if="$route.name != 'employee_search'">
                            <div class="form_check_box d-flex align-items-center">
                              <input type="checkbox" v-model="reviewCheckbox" :value="user.id" aria-label="..."
                                :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                                @change="checkSelected($event)" />
                              <label :for="'flexCheckDefault' + indx" class="">
                              </label>
                            </div>
                          </td>
                          <td>
                            <router-link class="hover_first_name" :to="{
                              name: view_employee_route,
                              params: { clientid: listConfig.clientId, id: user.u_id },
                            }">
                              <p>{{ user.first_name | capitalize }}</p>
                            </router-link>
                          </td>
                          <td>
                            <p>{{ user.last_name | capitalize }}</p>
                          </td>
                          <td>
                            <p class="email_tag">
                              <a :href="'mailto:' + user.email">{{ user.email }}</a>
                            </p>
                          </td>
                          <td>
                            <p>{{ user.region | capitalize }}</p>
                          </td>
                          <td v-if="showSelfAssessment" align="center" style="text-align: center">
                            <p>{{ user.saCount }}</p>
                          </td>
                          <td align="center" style="text-align: center">
                            <p>{{ user.eeCount }}</p>
                          </td>
                          <td align="center" style="text-align: center">
                            <div class="edit_btn">
                              <p>
                                <router-link :to="{
                                  name: view_employee_route,
                                  params: { clientid: listConfig.clientId, id: user.u_id },
                                }" class="viewemployee" title="View Employee">
                                  <img :src="require('@/assets/images/dashboard/View_Icon.svg')
                                    " alt="View Employee" />
                                </router-link>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="data_length == 0">
                          <td colspan="7" class="text-center">No Records found</td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div id="hash1" role="tabpanel" aria-labelledby="#hash1" class="tab-pane fade">
                <div class="Employee_search mt-2">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3" v-for="(user, indx) in users_data"
                      :key="indx">
                      <div class="white_card_box position-relative">
                        <div class="form-check" v-if="$route.name != 'employee_search'">
                          <input type="checkbox" v-model="reviewCheckbox" :value="user.id" aria-label="..."
                            :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                            @change="checkSelected($event)" />
                        </div>
                        <router-link :to="{
                          name: view_employee_route,
                          params: { clientid: listConfig.clientId, id: user.u_id },
                        }" class="viewemployee" title="View Employee">
                          <div class="person_info text-center p-4">
                            <img :src="base_url + '/avatars/' + user.avatar" />
                            <!-- <img :src="require('@/assets/images/card_person.png')"> -->
                            <h3 class="mt-2 Person_name dot_pers_nam">
                              {{ user.first_name | capitalize }}
                              {{ user.last_name | capitalize }}
                            </h3>
                          </div>
                          <div class="card_info">
                            <div class="row">
                              <div v-if="showSelfAssessment" class="col-6 col-sm-6 py-3">
                                <h6 class="text-center">
                                  {{ user.saCount }}
                                </h6>
                                <p class="text-center">Self-Assessment Activity</p>
                              </div>
                              <div :class="showSelfAssessment ? 'col-6 col-sm-6 py-3' : 'py-3'">
                                <h6 class="text-center">
                                  {{ user.eeCount }}
                                </h6>
                                <p class="text-center">Ergo Eval activity</p>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-if="!users_data.length" class="col-sm-12 col-md-12 col-lg-12">
                    <h3 class="text-center no_data card_no_data white_card border_radius_20">No Records found</h3>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-4">
                <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                  :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="previous" role="tabpanel" aria-labelledby="#previous"
        :class="checkReportType == 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
        <div class="row dataTables_wrapper no-footer" id="datatable_wrapper">
          <div class="
              col-xl-3 col-lg-3 col-md-12 col-xs-12
              text-start text-md-start text-lg-start
              align-self-center
              my-2
              mb-2 mb-lg-5
            ">
            <div class="dataTables_length float_none text-center text-lg-start" id="datatable_length">
              <label>Show
                <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                  @change="getEmployeesBysearch">
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option>500</option>
                </select>
                entries</label>
            </div>
          </div>
          <div class="col-xl-9 col-lg-9 col-md-12 col-xs-12">
            <div class="
                d-flex
                justify-content-lg-end justify-content-center
                align-items-center
                flex-wrap
                mb-4 mb-lg-4
              " id="mobileCenter">
              <div id="datatable_filter" class="dataTables_filter my-2" v-if="!reviewCheckbox.length">

                <label class="mb-0"><input type="search" class="" placeholder="Search" aria-controls="datatable"
                    v-model="listConfig.search_string" v-on:keyup.enter="getEmployeesBysearch" />
                  <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getEmployeesBysearch"
                    class="search_icon icon_level">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#7d8d9e" class="bi bi-search"
                      viewBox="0 0 16 16">
                      <path
                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </a>
                  <a href="javascript:void(0)" v-if="listConfig.search_string" @click="
                    listConfig.search_string = null;
                  getEmployeesBysearch();
                  " class="crossicon">
                    X
                  </a>
                </label>
              </div>
              <div class="visited_pages status_box marg_left wdth_sts my-2">
                <div class="heading">
                  <div class="form-group status_drop mb-0">
                    <v-select @input="getEmployeesBysearch" :reduce="(status_list) => status_list.id"
                      :options="status_list" label="name" placeholder="All Status" name="Status"
                      v-model="listConfig.search_status" v-validate="'required'" v-if="!reviewCheckbox.length" />
                    <small class="error text-danger" v-show="errors.has('addUserForm.Status')">
                      {{ errors.first("addUserForm.Status") }}
                    </small>
                  </div>
                </div>
              </div>
              <a href="javascript:" class="ml_12 xl_download" v-if="reviewCheckbox.length"
                @click="exportTableData('previous')">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                  <g data-name="Group 12689">
                    <path data-name="Path 114"
                      d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                      transform="translate(-3 -3)" style="
                        fill: none;
                        stroke: #a9a9a9;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-width: 2px;
                      " />
                  </g>
                </svg>
                Download
              </a>
              <div class="
                  tab_report
                  toggle_switch
                  marg_left
                  list_card_switch
                  me-0
                  my-2
                " v-show="!reviewCheckbox.length">
                <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs">
                  <li role="presentation" class="nav-item list-item">
                    <button id="percent2-tab" data-bs-toggle="tab" data-bs-target="#percent2" type="button" role="tab"
                      aria-controls="percent2" aria-selected="true" class="nav-link list-link active">
                      List
                    </button>
                  </li>
                  <li role="presentation" class="nav-item list-item">
                    <button id="hash2-tab" data-bs-toggle="tab" data-bs-target="#hash2" type="button" role="tab"
                      aria-controls="hash2" aria-selected="false" class="nav-link list-link">
                      Card
                    </button>
                  </li>
                </ul>
              </div>
              <div class="table_search ergo_eval_table" v-if="$route.name == 'employee_search' && users_data.length">
                <div class="d-flex align-items-center">
                  <a href="javascript:" class="ml_12 xl_download mt16-0 me-2" @click="exportAllData('previous')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                      <g data-name="Group 12689">
                        <path data-name="Path 114"
                          d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                          transform="translate(-3 -3)" style="
                              fill: none;
                              stroke: #1a191c;
                              stroke-linecap: round;
                              stroke-linejoin: round;
                              stroke-width: 2px;
                            " />
                      </g>
                    </svg>
                    Download All
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div id="myTab2Content" class="tab-content">
              <div id="percent2" role="tabpanel" aria-labelledby="#percent2" class="tab-pane fade active show">
                <div class="table_card all_user">
                  <div class="table-responsive table_audit_log report_table emp_table">
                    <table align="justify" class="table table-bordered table dataTable no-footer">
                      <thead>
                        <tr>
                          <th style="min-width: 10px" class="sort sml_check" v-if="$route.name != 'employee_search'">
                            <div class="form_check_box d-flex align-items-center mt-1">
                              <input type="checkbox" v-model="selectAll" aria-label="..." id="flexCheckDefault"
                                class="form-check-input ms-0" @change="checkSelected($event)" />
                              <label for="flexCheckDefault" class=""> </label>
                            </div>
                          </th>
                          <th align="center" class="sort sml_n_last_first">
                            <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'first_name'"
                              @sorting="sorting($event)" />
                          </th>
                          <th align="center" class="sort sml_n_last_first">
                            <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'last_name'"
                              @sorting="sorting($event)" />
                          </th>
                          <th align="center" class="sort">
                            <SortIcons :lable="'Email Address'" :listConfig="listConfig" :sortBy="'email'"
                              @sorting="sorting($event)" />
                          </th>
                          <th align="center" class="sort sml_region">
                            <SortIcons :lable="'Region'" :listConfig="listConfig" :sortBy="'region'"
                              @sorting="sorting($event)" />
                          </th>
                          <th v-if="showSelfAssessment" align="center" style="text-align: center">
                            Self-Assessment <br />
                            Activity
                          </th>
                          <th align="center" style="text-align: center" class="sml_region">
                            Ergo Eval <br />
                            Activity
                          </th>
                          <th align="center" style="text-align: center" scope="col" class="sml_region">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- Main Loop -->
                        <tr v-for="(user, indx) in users_data" :key="indx">
                          <td style="min-width: 10px" v-if="$route.name != 'employee_search'">
                            <div class="form_check_box d-flex align-items-center">
                              <input type="checkbox" v-model="reviewCheckbox" :value="user.id" aria-label="..."
                                :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                                @change="checkSelected($event)" />
                              <label :for="'flexCheckDefault' + indx" class="">
                              </label>
                            </div>
                          </td>
                          <td>
                            <router-link class="hover_first_name" :to="{
                              name: view_employee_route_prev,
                              params: { clientid: listConfig.clientId, id: user.u_id },
                            }">
                              <p>{{ user.first_name | capitalize }}</p>
                            </router-link>
                          </td>
                          <td>
                            <p>{{ user.last_name | capitalize }}</p>
                          </td>
                          <td>
                            <p class="email_tag">
                              <a :href="'mailto:' + user.email">{{ user.email }}</a>
                            </p>
                          </td>
                          <td>
                            <p>{{ user.region | capitalize }}</p>
                            <!-- </router-link>  -->
                          </td>
                          <td v-if="showSelfAssessment" align="center" style="text-align: center">
                            <p>{{ user.pre_self_assessment_count }}</p>
                            <!-- </router-link> -->
                          </td>
                          <td align="center" style="text-align: center">
                            <p>{{ user.pre_ergo_eval_count }}</p>
                            <!-- </router-link> -->
                          </td>
                          <td align="center" style="text-align: center">
                            <div class="edit_btn">
                              <p>
                                <router-link :to="{
                                  name: view_employee_route_prev,
                                  params: { clientid: listConfig.clientId, id: user.u_id },
                                }" class="viewemployee" title="View Employee">
                                  <img :src="require('@/assets/images/dashboard/View_Icon.svg')
                                    " alt="View Employee" />
                                </router-link>
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="data_length == 0">
                          <td colspan="7" class="text-center">No Records found</td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                </div>
              </div>
              <div id="hash2" role="tabpanel" aria-labelledby="#hash2" class="tab-pane fade">
                <div class="Employee_search mt-2">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-3" v-for="(user, indx) in users_data"
                      :key="indx">
                      <div class="white_card_box position-relative">
                        <div class="form-check" v-if="$route.name != 'employee_search'">
                          <input type="checkbox" v-model="reviewCheckbox" :value="user.id" aria-label="..."
                            :id="'flexCheckDefault' + indx" class="form-check-input ms-0"
                            @change="checkSelected($event)" />
                        </div>
                        <router-link :to="{
                          name: view_employee_route_prev,
                          params: { id: user.u_id },
                        }" class="viewemployee" title="View Employee">
                          <div class="person_info text-center p-4">
                            <img :src="base_url + '/avatars/' + user.avatar" />
                            <!-- <img :src="require('@/assets/images/card_person.png')"> -->
                            <h3 class="mt-2 Person_name dot_pers_nam">
                              {{ user.first_name | capitalize }}
                              {{ user.last_name | capitalize }}
                            </h3>
                          </div>
                          <div class="card_info">
                            <div class="row">
                              <div v-if="showSelfAssessment" class="col-6 col-sm-6 py-3">
                                <h6 class="text-center">
                                  {{ user.pre_self_assessment_count }}
                                </h6>
                                <p class="text-center">Self-Assessment Activity</p>
                              </div>
                              <div :class="showSelfAssessment ? 'col-6 col-sm-6 py-3' : 'py-3'">
                                <h6 class="text-center">
                                  {{ user.pre_ergo_eval_count }}
                                </h6>
                                <p class="text-center">Ergo Eval activity</p>
                              </div>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-if="!users_data.length" class="col-sm-12 col-md-12 col-lg-12">
                    <h3 class="text-center no_data card_no_data white_card border_radius_20">No Records found</h3>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-4">
                <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                  :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import exportFromJSON from "export-from-json";
import ConfirmDialogue from "@/components/ConfirmDialogue";

export default {
  name: "employee_search",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    Datepicker,
    ConfirmDialogue
  },
  data() {
    return {
      exportData: [],
      show: false,
      users: [],
      users_data: [],
      selected: true,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "email",
        order: "ASC",
        search_string: null,
        start_date: null,
        end_date: null,
        search_status: null,
        regionBy: null,
      },
      data_length: 0,
      isDisabled: "",
      addPermission: false,
      deletePermission: false,
      delete_id: 0,
      delete_res: "",
      delete_reason: "",
      limitmaxCount: 150,
      maxCount: 150,
      remainingCount: 150,
      hasError: false,
      reviewCheckbox: [],
      status_list: [
        {
          id: null,
          name: "All Status",
        },
        {
          id: "active",
          name: "Active",
        },
        {
          id: "inactive",
          name: "Inactive",
        },
      ],
      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      isCheckAll: false,
      selectedUser: [],
      region_list: [{
        id: 'ALL',
        name: 'ALL',
      }],
      base_url: null,
      view_employee_route: "view_employee",
      view_employee_route_prev: "view_employee_prev",
      clientName: 'Employee Search',
      checkReportType: '3',
      showSelfAssessment: true,
      previousStartDate: null,
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.users_data
          ? this.reviewCheckbox.length == this.users_data.length
          : false;
      },
      set: function (value) {
        var reviewCheckbox = [];
        if (value) {
          this.users_data.forEach(function (data) {
            //if (data.editStatus) {
            reviewCheckbox.push(data.id);
            //}
          });
        }
        this.reviewCheckbox = reviewCheckbox;
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getEmployees();
    },
  },
  destroyed() {
    this.$parent.clientManagerDashboard = false;
    this.$parent.employeeDashboard = false;
  },
  mounted() {
    //Get all regions from Client data...
    var clientData = this.$store.getters["auth/authUser"];
    if (clientData.client.client_regions) {
      var clientRegions = clientData.client.client_regions.replace(/[^a-zA-Z,/ ]/g, "");
      var clientRegionsArr = clientRegions.split(",");
      for (let i = 0; i < clientRegionsArr.length; i++) {
        this.region_list.push({ "id": clientRegionsArr[i], "name": clientRegionsArr[i] });
      }
    }
    if (clientData.client.report_type == 1) {
      this.checkReportType = '1';
      // this.getReports('alchemer');
    } else if (clientData.client.report_type == 2) {
      this.checkReportType = '2';
      // this.getReports('qualtrics');
    } else {
      this.checkReportType = '3';
      // this.getReports('alchemer');
    }

    if (this.$router.currentRoute.name == "evaluator_employee_search") {
      this.$parent.employeeDashboard = true;
      this.view_employee_route = "evaluator_employee_view";
      this.view_employee_route_prev = "evaluator_employee_view_prev";
    } else if (this.$router.currentRoute.name == "client_employee_search") {
      this.$parent.clientManagerDashboard = true;
      this.view_employee_route = "client_employee_view";
      this.view_employee_route_prev = "client_employee_view_prev";
    } else {
      if (clientData.client.client_type != 1) {
        this.showSelfAssessment = false;
      }
      this.$parent.employeeDashboard = true;
      this.view_employee_route = "view_employee";
      this.view_employee_route_prev = "view_employee_prev";
    }
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "email",
      order: "ASC",
      search_string: null,
      start_date: null,
      end_date: null,
      regionBy: null,
      search_status: null,
    };
    this.getEmployees();
  },
  // beforeMount() {
  //   // this.$parent.$refs.fullpageloader.is_full_page_loader = true;
  //   if (localStorage.getItem("userToken")) {
  //     window.addEventListener("scroll", this.scrollvue);
  //     // Store for check permission
  //     let res = this.$store.dispatch("auth/getAuthUser");
  //     res
  //       .then((value) => {
  //         let allPermission = value.user.roles.all_permission;
  //         if (allPermission.includes("users-edit")) {
  //           this.addPermission = true;
  //         }
  //         if (allPermission.includes("users-delete")) {
  //           this.deletePermission = true;
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     this.tokenExpired();
  //   }
  // },
  methods: {
    checkSelected(event) {
      if (event.target.checked) {
        document.getElementById("mobileCenter").classList.add("mobile-center");
      } else {
        if (this.reviewCheckbox.length == 0) {
          document
            .getElementById("mobileCenter")
            .classList.remove("mobile-center");
        }
      }
    },
    async exportAllData(type) {
      const confirmDownload = await this.$refs.confirmDialogue.show({
        title: "Download",
        message: "Are you sure you want to download?",
        okButton: "Download",
      });

      if (!confirmDownload || !localStorage.getItem("userToken")) return;

      this.$parent.$refs.fullpageloader.is_full_page_loader = true;

      const config = {
        method: "post",
        url: `${process.env.VUE_APP_API_URL}/api/employee-search-download-all`,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(this.listConfig) },
      };

      try {
        const { data } = await this.axios(config);
        this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        const downloadResponse = this.decodeAPIResponse(data.data);

        if (!downloadResponse) return;

        const filterExport = downloadResponse.map(responseData => ({
          "First Name": responseData.first_name,
          "Last Name": responseData.last_name,
          "Email": responseData.email,
          "Region": responseData.region,
          "Self-Assessment Activity": type === 'current' ? responseData.saCount : responseData.pre_self_assessment_count,
          "Ergo Eval Activity": type === 'current' ? responseData.eeCount : responseData.pre_ergo_eval_count,
        }));

        const fileName = "EmployeeList";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data: filterExport, fileName, exportType });

        // Audit log for download
        const auditLogConfig = {
          method: "post",
          url: `${process.env.VUE_APP_API_URL}/api/audit-log-for-download`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: this.encodeAPIRequestWithImages({ section: 'Employee List', dashboard: 'Employee Search' }),
        };

        try {
          const auditLogResponse = await this.axios(auditLogConfig);
          console.log(auditLogResponse.data.message);
        } catch (auditLogError) {
          if (auditLogError.response.status === 401) {
            this.tokenExpired();
          }
        }
      } catch (error) {
        this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        this.$toast.error(error.response.data.error, {
          position: "top-right",
          duration: 5000,
        });
        if (error.response.status === 401) {
          this.$router.push({ name: "login" });
        }
      }
    },
    /** Export Csv File based on selected checkboxes */
    exportTableData(type) {
      var filterExport = [];
      var _this = this;
      var data;
      if (_this.reviewCheckbox.length) {
        _this.users_data.forEach(function (responseData) {
          if (_this.reviewCheckbox.includes(responseData.id)) {
            if (type == 'current') {
              filterExport.push({
                "First Name": responseData.first_name,
                "Last Name": responseData.last_name,
                "Email": responseData.email,
                "Region": responseData.region,
                "Self-Assessment Activity": responseData.saCount,
                "Ergo Eval Activity": responseData.eeCount,
              });
            } else {
              filterExport.push({
                "First Name": responseData.first_name,
                "Last Name": responseData.last_name,
                "Email": responseData.email,
                "Region": responseData.region,
                "Self-Assessment Activity": responseData.pre_self_assessment_count,
                "Ergo Eval Activity": responseData.pre_ergo_eval_count,
              });
            }
          }
        });
        data = filterExport;
      } else {
        data = this.exportData; // exportData is calling from Laravel Controller
      }
      const fileName = "EmployeeList";
      const exportType = exportFromJSON.types.csv;
      //Audit log for download
      var requestData = { 'section': 'Employee List', 'dashboard': 'Employee Search' };
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/audit-log-for-download",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: this.encodeAPIRequestWithImages(requestData),
      };
      this.axios(config)
        .then(({ data }) => {
          console.log(data.message);
        }).catch(({ response }) => {
          if (response.status == 401) {
            _this.tokenExpired();
          }
        });


      if (data) exportFromJSON({ data, fileName, exportType });
    },
    userAllcheck: function () {
      if (this.isCheckAll == false) {
        const selected = this.users_data.map((u) => u.id);
        this.selectedUser = selected;
      } else {
        this.selectedUser = [];
      }
    },
    resetAll() {
      this.listConfig.end_date = "";
      this.listConfig.start_date = "";
      this.listConfig.regionBy = "";
      this.isDisabled = true;
      this.getEmployees();
    },
    selectSearchDate() {
      this.$nextTick(() => {
        this.getEmployeesBysearch();
      });
    },
    showDatePicker() {
      this.$refs.dp2.showCalendar();
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getEmployees();
    },
    getEmployeesBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getEmployees();
    },
    getEmployees() {
      var _this = this;
      if (_this.$route.params.id != "" && _this.$route.params.id != undefined) {
        _this.listConfig.clientId = _this.$route.params.id;
      }
      if (localStorage.getItem("userToken")) {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/employee-search-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig) },
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.clientName = decodedJson.clientName;
            _this.users_data = decodedJson.users;
            _this.exportData = decodedJson.exportData;
            _this.data_length = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.reviewCheckbox = [];
            _this.selectAll = false;
          })
          .catch(({ response }) => {
            if (response.status == 404) {
              _this.$router.push({ name: "404" });
            }

            if (response.status == 403) {
              _this.$router.push({ name: "403" });
            }

            if (response.status == 401) {
              _this.$router.push({ name: "login" });
            }

            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          });
      } else {
        _this.tokenExpired();
      }
    },
    // dataTableWithData() {
    //   $("#datatable").DataTable({
    //     bDestroy: true,
    //     aoColumnDefs: [
    //       {
    //         bSortable: false,
    //         aTargets: [-1],
    //       },
    //     ],
    //   });
    // },
    openModel(delete_user) {
      this.delete_reason = "";
      this.delete_res = "";
      this.delete_id = delete_user;
    },
    closeModel() {
      document.getElementsByClassName("btn-close")[0].click();
    },
    countdown: function () {
      this.remainingCount = this.maxCount - this.delete_reason.length;
      this.hasError = this.remainingCount < 0;
      if (this.hasError) {
        console.log("Haserror:", this.hasError);
        return;
      }
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      this.getEmployeesBysearch();
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (
    //       this.listConfig.end_date != "" &&
    //       this.listConfig.end_date != null
    //     ) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
            position: "top-right",
            duration: 5000,
          });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
  },
};
</script>